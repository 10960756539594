<template>
  <v-app id="inspire">
    <notifications group="gral_notif" position="bottom right" :width="400" />
    <v-main>
      <v-container fluid fill-height class="fondoApp">
        <v-layout align-center justify-center>
          <v-flex xs12 sm7 md5>
            <v-form v-on:submit.prevent>
              <v-card class="elevation-12">
                <v-img src="@/assets/BANNER-2.png"></v-img>
                <v-toolbar dense dark color="#86212b">
                  <v-spacer></v-spacer>
                  <v-toolbar-title class="text-md-center"
                    >Servicios de Secretaría General</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text style="padding-top: 30px">
                  <v-row>
                    <v-col xs="12" sm="12" md="5">
                      <p class="text-center">
                        <img
                          src="@/assets/ESCUDO 150X75 px.png"
                          id="imagen"
                        />
                      </p>
                    </v-col>
                    <v-col xs="12" sm="12" md="7" style="padding-top: 30px">
                      <v-text-field
                        v-model="codigo"
                        prepend-icon="mdi-account"
                        name="SG-codigo"
                        label="Código SIIAU"
                        type="text"
                        :disabled="loading"
                        color="blue-grey"
                        outlined
                        dense
                        :error-messages="codigoErrors"
                        @input="$v.codigo.$touch()"
                        @blur="$v.codigo.$touch()"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="password"
                        prepend-icon="mdi-lock"
                        label="NIP"
                        type="password"
                        color="blue-grey"
                        outlined
                        dense
                        :disabled="loading"
                        :error-messages="passwordErrors"
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                      >
                      </v-text-field>
                      <p class="errorMessage">{{ errorMessage }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    type="submit"
                    color="#86212b"
                    @click="submit()"
                    dark
                    :loading="loading"
                    >Iniciar sesión</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
                <v-card-text class="text-center">
                  <div style="padding-top: 1px" class="text-center">
                    <a style="text-decoration: none; font-size: 12px" class="link" :href="`http://transparencia.udg.mx/aviso-confidencialidad-integral`" target="_blank">Aviso de privacidad</a>
                  </div>
                </v-card-text>
                <v-card-text class="text-right">
                  <i>Versión {{ systemVersion }}</i>
                </v-card-text>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { loginServer } from "./login.service";

export default {
  name: "loginView",
  mixins: [validationMixin],
  components: {},
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "systemVersion"]),
    codigoErrors() {
      const errors = [];
      if (!this.$v.codigo.$dirty) return errors;
      !this.$v.codigo.required && errors.push("Campo requerido.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength && errors.push("Mínimo 3 caracteres.");
      !this.$v.password.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    codigo: { required, minLength: minLength(5) },
    password: { required, minLength: minLength(3) },
  },
  data() {
    return {
      loading: false,
      codigo: "",
      password: "",
      errorMessage: "",
    };
  },
  created() {
    if (localStorage.getItem("sg-token")) {
      const token = localStorage.getItem("sg-token");
      this.$store.dispatch("setSessionToken", token);
      this.$router.push("/apps");
    }
  },
  methods: {
    async submit() {
      if (this.camposValidos()) {
        try {
          this.loading = true;
          const config = { headers: {} };
          const data = {
            codigo: this.codigo,
            nip: this.password,
          };
          const serverResponse = await loginServer(this.httpURL, data, config);
          this.$validateResponse(serverResponse);
          this.loading = false;
          if (serverResponse.ok) {
            localStorage.setItem("sg-token", serverResponse.token);
            this.$store.dispatch("setSessionToken", serverResponse.token);
            this.$store.dispatch("setUserName", serverResponse.userName);
            this.$store.dispatch("setCodigoUsuario", this.codigo);
            this.$router.push("/apps");
          } 
          else {
            console.log(serverResponse)
            this.errorMessage = serverResponse.mensaje;
          }
        } catch (error) {
          console.log(error);
          this.$router.push("/login-code");
          this.loading = false;
          this.$notificarError(error);
        }
      }
    },
    camposValidos() {
      this.$v.$touch();
      return !this.$v.codigo.$invalid && !this.$v.password.$invalid;
    },
  },
};
</script>

<style>
.fondoApp {
  background: #b0bec5;
}
.errorMessage {
  color: #ff0000;
}
.invalid_input {
  border: 1px solid red;
}
</style>
